import React from 'react';
import {useLayoutEffect} from 'react';
import '../App.css';
import '../fonts.css';

import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";

function LCTourContent() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    
    <div className='page'>

        <div className='parchment-box'>
            <h1 className='itin-title'>Lexington & Concord Tour</h1>
        </div>
        
        <div className='blue-box' id='beige'>

            <div className='picture-text-container'>
                    <img id='minuteman' src={require("../images/Minuteman-small.jpg")} />
                <div className='right-column-list' id='itin-column'>
                <h2 className='subheading3' id='center-phone'>Itinerary for the Day</h2>
                    <ul className='details-list'>
                        <li>Harvard College</li>
                        <li>MIT</li>
                        <li>City of Cambridge</li>
                        <li>Battle Green</li>
                        <li>Buckman Tavern</li>
                        <li>Lexington Visitors Center</li>
                        <li>Battle Road</li>
                        <li>Paul Revere’s Arrest Site</li>
                        <li>Old Burying Ground</li>
                        <li>Old Manse</li>
                        <li>North Bridge</li>
                        <li>Concord Museum</li>
                    </ul>
                </div>
            </div>

            <div className='timing'>
                <hr />
                <p>Pickups for this tour start at 8:05 AM, and the tour length is approximately 8.5 hours.</p>
                        <p>Adults: $129</p>
                        <p>Children: $79</p>
                <hr />
            </div>

            <img id='battleroad-map' src={require("../images/battleroad-map.jpg")} />
        </div>

        <div className='parchment-box'>
            <h1 className='itin-description-title'>WALK IN THE FOOTSTEPS OF THE REVOLUTION</h1>
              <div className='justify'>
                <p>Prepare for a spectacular view of the Charles River as you travel to the City of Cambridge, past the Massachusetts Institute of Technology campus. Discover the real name of the mysterious “Mr. Smith”, whose donation made it possible to move the campus from Boston over to Cambridge. Traveling along Massachusetts Avenue, you will enjoy the sights and sounds of Cambridge (1637). Did you know that one of the greatest concentrations of high technology, biotech and pharmaceutical research and development is in Boston and Cambridge? Entering Harvard Square, you will see Harvard (1639) College and the oldest dorms, once used to house Continental Army troops.</p>

                <p>Your route takes you along the path traveled by that other Midnight Rider, William Dawes. At Cambridge Common, George Washington assumed command of the Continental Army under an elm tree. An offshoot of the “Washington Elm” lives on in tribute. With a view of Harvard Square, Revolutionary era cannons, and a unique monument to William Dawes, your tour guide will move along to Tory Row, home to Washington’s Army Headquarters, where he plotted the siege of Boston.</p>

                <p>Retrace the footsteps of the 700 angry young Red Coats marching upon Lexington and Concord, searching for canon and munitions on that eventful day of April 19, 1775. Imagine the cacophony of sounds made by countless church and belfry tower bells ringing the alarm across Massachusetts, warning of the imminent arrival of the King’s finest troops.</p>

                <p>Ample time is allotted to walk Lexington’s Battle Green, to experience the sites, monuments, and statues of April 19, 1775 close-up. Imagine those seventy-seven stout Minutemen and militia under the command of Captain John Parker pouring out of the Buckman Tavern to confront tyranny. </p>

                <p>Did you know that Lexington’s Battle Green is the oldest military burial site in America? A 1799 monument was erected that has the names of those who made the ultimate sacrifice in defense of freedom that historic day in history inscribed. Stand before the iconic statue of Captain John Parker, musket in hand, an experienced combat veteran of the French and Indian War. Captain Parker would lead his men into the throes of battle throughout that bloody day that changed the world.</p>

                <p>After visiting the new Lexington Visitor Center, to study an amazing battle diorama, stand before the original Buckman Tavern (1710). This is where those brave Minutemen and militia awaited the early morning arrival of a column of 700 light infantry and grenadiers, dreaded Red Coats, marching out of Boston to recover several light cannons and to teach the local farmers a lesson or two. The Royal Governor in Boston was rightfully fearful that a rebellion against King George III was brewing in response to coercive policies and taxation without representation.</p>

                <p>Departing Lexington, you will travel alongside Battle Road, stopping at the original Hartwell Tavern, where a ferocious battle took place between the surging militia forces and the long column of retreating Red Coats.</p>

                <p>Upon entering Concord proper your guide will point out the Wayside, home of Nathaniel Hawthorne and Louise May Alcott (when younger) and her beloved Orchard House. Almost across the street is Ralph Waldo Emerson’s House. Further along is the historic Wright Tavern, Old Burying Ground, and the Concord Inn (1716), a vibrant albeit haunted “witness” to the British commanders who wined and dined for lunch while their troops searched house-to-house and farm-to-farm for cannons and gunpowder.</p>

                <p>Soon the vista Old North Bridge site opens before you. This is where Major John Buttrick and hundreds of militia would engage the Red Coats in a bridge battle known as “the shot heard round the world.” This skirmish is the first time a colonial officer ordered his men to fire upon British soldiers. At the base of the bridge is a solemn gravestone marking the spot where young Red Coats from the battle are buried, never to return to Mother England.</p>

                <p>To the left of the North Bridge is the Old Manse where minister William Emerson and family were able to watch the battle from the upstairs windows.</p>

                <p>Next, your guide will reverse direction along Battle Road, back. This provides another opportunity to imagine the chaotic scene, musket fire and hand-to-hand combat (bayonets) that broke out between that long column of Red Coats and militia about one mile outside of the village.</p>

                <p>New for America's Birthday 2025, and one of the highlights of your historic adventure, is a visit to the Concord Museum. The Concord Museum contains the most comprehensive collection of items and exhibits related to the revolutionary events of 1775. This amazing museum holds over 45,000 objects, with collections spanning the 12000 year history of Lexington and Concord. Archeology, Thoreau, Hawthorne and Emerson, Revolutionary War, Samplers, timepieces, and much more are on display.</p>
            </div>

            <Link to="/tour-details" className='center'>
                        <h2 className='details-button'>MORE DETAILS</h2>
                </Link>

        </div>
    </div>
    );
}

export default LCTourContent;