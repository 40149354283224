import React from 'react';
import '../App.css';
import '../fonts.css';

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

function TourDetailsContent() {
    return (

    <><div className="parallax-tour">
            <div id="parallax-box" className='overlay'>
                <div className="container">
                    <h2>TOUR DETAILS</h2>
                    <h2 className='subtitle'>Everything you need to know to be fully prepared for your unforgettable journey to Boston, Lexington,
                        Plymouth, or Cape Cod</h2>
                </div>
            </div>
        </div>
        
        
    <div className='page'>
            <div className='parchment-box'>
              <div className='picture-text-container'>
                <div className='left-column-text' id='wide-box'>
                    <h2 className='subheading2' id='pickup-title'>PICKUP & DROP-OFF SCHEDULE</h2>
                        <h2 className='subheading4'>GET READY FOR A DAY OF HISTORY & FUN</h2>
                            <br />
                                <p> <strong>Lexington & Concord</strong>
                                        <ul>
                                            <li>Pickups for this tour start at 8:05 AM</li>
                                            <li>Tour length is approximately 8.5 hours</li>
                                        </ul>
                                    <strong>Plymouth & Cape Cod</strong>
                                        <ul>
                                            <li>Pickups for this tour start at 8:05 AM</li>
                                            <li>Tour length is approximately 9 hours</li>
                                        </ul>
                                    <strong>New England Seacoast Tour</strong>
                                        <ul>
                                            <li>Pickups for this tour start at 8:05 AM</li>
                                            <li>Tour length is approximately 9 hours</li>
                                        </ul>
                                </p>
                </div>


                <div className='picture' id='pickup-map'>
                    <img id='cookware' src={require("../images/pickup-map-boston.png")} />
                </div>

                </div>
            </div>

            <div className='blue-box'>

                <h2 className='subheading2'>PICKUP & DROP-OFF LOCATIONS</h2>
                    <h2 className='subheading4'>YOU MUST SELECT ONE OF THE FOUR OPTIONS BELOW FOR PICKUP:</h2>
                      <br />
                        <p><strong>Stop 1 (8:05 AM): 1 Central Wharf – New England Aquarium</strong></p>
                            <p className='pickup-details'> This stop is outside of Legal Seafoods. 
                            Walking distance from Boston Marriot Long Wharf (adjacent to hotel), Canopy By Hilton, The Bostonian Hotel, the Hilton Boston Downtown, Intercontinental Hotel, Boston Harbor Hotel, and South Station.</p>
                        
                            <a href="https://maps.app.goo.gl/cZeDrwtz6tGyJVLe9" target="_blank">
                                <h2 className='map-button'>SEE ON MAP</h2>
                            </a>
                        
                        <p><strong>Stop 2 (8:25 AM): 8 Park Plaza – State Transportation Building (big brick building)</strong></p>
                            <p className='pickup-details'> This stop is in front of PF Chang's and Chipotle. Walking distance from Revere Hotel (across street), Hilton Park Plaza, W Hotel (across street), Moxie Hotel (across street), Courtyard By Marriott, and DoubleTree By Hilton.</p>
                        
                            <a href="https://maps.app.goo.gl/iDj9er9pJK32Bf8R7" target="_blank">
                                <h2 className='map-button'>SEE ON MAP</h2>
                            </a>
                         
                        <p><strong>Stop 3 (8:35 AM): 138 Saint James Ave – Fairmont Copley Plaza Hotel</strong></p>
                            <p className='pickup-details'> This stop is directly across from the side entrance to the Copley Plaza Hotel on St. James Street, Hancock Tower and adjacent to Trinity Church. Walking distance from Westin Copley Place, Copley Square Hotel, Charlesmark Hotel and Lenox Hotel.</p>
                        
                            <a href="https://maps.app.goo.gl/vL4tk4f8zFgr5GBLA" target="_blank">
                                <h2 className='map-button'>SEE ON MAP</h2>
                            </a>
                        
                        <p><strong>Stop 4 (8:50 AM): 39 Dalton Street</strong></p>
                            <p className='pickup-details'> Adjacent to the Sheraton and Hilton hotels and within easy walking distance to the Berkely College of Music and entrance to the Prudential Shops.</p>
            
                            <a href="https://maps.app.goo.gl/Jz4v9yB4bTqu9YTt8" target="_blank">
                                <h2 className='map-button'>SEE ON MAP</h2>
                            </a>
            
            </div>

            <div className='parchment-box'>
            <h2 className='subheading2'>FACTS, TIPS,  & IMPORTANT INFO</h2>
                <h2 className='subheading4'>BE FULLY PREPARED FOR YOUR DAY TOUR</h2>

                <p id='details-heading'><strong>More Tour Details</strong></p>
                    <ul className='details-list'>
                        <li>Ride comfortably in our new air-conditioned minibus</li>
                        <li>Tours restricted to 14-32 participants</li>
                        <li>We recommend that you bring a small backpack for personal items</li>
                        <li>Accessibility: Lots of walking but at a leisurely pace, on mostly level ground. Not suitable for those with difficulty walking.</li>
                        <li>Lunch break is approximately 30 minutes. We will stop at a restaurant with plenty of options. Call ahead to inquire about options if you are concerned about an allergy or dietary restriction.</li>
                        <li>To prepare for the sun in all climates, wear sunscreen and a hat</li>
                        <li>March to June: Weather can be very chilly, so bring a coat or jacket</li>
                        <li>July and August: Weather can be cool in the morning, so prepare to wear a light jacket</li>
                        <li>September to October: Weather can be unpredictable with alternating cold, windy, and sunny days, so bring a coat or jacket</li>
                     
                    </ul>

                <p id='details-heading'><strong>Date Exchanges</strong></p>

                    <p>Guests may change their reservation prior to 24 hours of the trip departure at no charge. However, the guest is fully responsible if the fare price for the new date/time selected is more expensive. Exchanges are based upon seat availability.</p>

                <p id='details-heading'><strong>Discounts</strong></p>

                    <p>We recommend reserving your trip well in advance to ensure availability, as our trips fill up quickly due to the unique historic nature of the sites and small group size limit. Please refer to special discounts for early bookings.</p>

                    <ul className='details-list'>
                        <li>Veterans are entitled to combine their 5% discount on tickets in addition to any other discount that may be offered.</li>
                        <li>Seniors 65 and older are entitled to a 5% discount on tickets</li>
                        <li>Discounted offers may not be combined with any other ticket offers other than for vets (including Commonwealth vets) or promotions unless specified. All offers, events, tickets, age ranges, services, attractions, and entertainment may be seasonal and are subject to change without notice. Discounts, products, and vendors are subject to change without notice or rescheduling to a future date.</li>
                        </ul>

                <p id='details-heading'><strong>Reservations</strong></p>

                    <p>Great Boston Tours reserves the right to change museums and sites based on weather, availability, and demand to those of equal or greater value.</p>

                <p id='details-heading'><strong>Refunds</strong></p>

                    <p>All trips are refundable in full, except for a 6% processing fee, up to 24 hours before your trip date. There will be a 100% cancellation fee applied to all tours canceled within 48 hours of the tour departure.</p>

                    <p>Proof of Purchase is required for refund processing. Credit for all returns will be in the same manner as original payment.</p>

                    <p>If we cancel your tour/trip due to inclement weather, equipment breakdown, civil order, or other force majeure event we offer guests a choice of a refund or another tour date.</p>

                <p id='details-heading'><strong>No Shows</strong></p>

                    <p>Due to limited seating availability, date and or time specific tickets are non-refundable if they are not used on the reservation date (no show). You may reschedule your trip date up to forty-eight hours in advance of the tour/trip time under our rescheduling terms.</p>

                <p id='details-heading'><strong>Group Tour Cancellation Refunds</strong></p>

                    <p>Group tour cancellation of trips are refundable except for a 6% processing fee up to 30 days before your group trip date. There will be a 75% cancellation fee except for a 6% processing fee applied to tours canceled 29 - 15 days prior to the tour date and a 100% cancellation fee for tours canceled 14 days or less to the tour departure date.</p>

                    <p>Notice must be provided by email to Richard@GreatBostonTours.com to confirm you are cancelling.</p>

                    <p>Note that if your tickets were purchased from a third-party booking site or voucher company such as Viator, Expedia, Trip Advisor, etc. the refund policy of such third party booking site or voucher company will apply.</p>

                <p id='details-heading'><strong>Money Back Guarantee</strong></p>

                    <p>GreatBostonTours is fully committed to providing our customers the finest historic tour experience. We have such confidence in the quality of our tours, placing safety and customer service first, that we provide a 100% GUARANTEE of satisfaction to the purchaser of the tour or your money back minus the cost of museum admissions and complimentary food.</p>

                <p id='details-heading'><strong>Laws and Regulations</strong></p>

                    <ul className='details-list'>
                        <li>No smoking or vaping in the bus</li>
                        <li>No alcoholic beverages (open alcoholic beverage container law is in effect in Massachusetts)</li>
                        <li>No children under the age of 18 without an accompanying adult</li>
                        <li>The law requires that infants and children must ride in a child passenger restraint seat until they are at least 5 years old and weigh more than 40 lbs. Children older than 5 and more than 40 lbs. must be secured in a booster seat until the child is 8 years old and more than 49 lbs. Any child over 8 must be protected by a seat belt.</li>
                        <li>No pets</li>
                        <li>Many historic sites prohibit the use of flash photography</li>
                        <li>Passengers must remain seated while the bus is in motion for safety</li>
                        <li>No “standees” permitted for safety</li>
                    </ul>

                <p id='details-heading'><strong>Acknowledgement and Agreement of Risk & Release of Liability</strong></p>

                    <p>Great Boston Tours LLC conducts activities that involve inherent risk such as trip and fall and other accidents. Despite all proper precautions and planning, risk of injury or death while participating in any activity does exist and:</p>

                        <p>(a) You are aware of and accept all risk involved with any activity associated with Great Boston Tours including but not limited to sightseeing, transport, walking on even and uneven terrain, up and down stairs and adverse weather.</p>

                        <p>(b) You are adequately prepared and healthy enough for all activities provided during our tours.</p>

                        <p>(c) You will heed any direction or order related to safety given by a tour guide or driver at any time.</p>

            </div>

    </div>
</>
                    )
                    }

                    export default TourDetailsContent;