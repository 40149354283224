import './App.css';
import Home from './Home';
import About from './About';
import TourDetails from './TourDetails';
import LCTour from './components/LCTour';
import PCCTour from './components/PCCTour';
import NESTour from './components/NESTour';
import Itineraries from './Itineraries';
import Privacy from './Privacy';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";
import React from 'react';

function App() {

    return (
      <Router>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/itineraries" element={<Itineraries />} />
          <Route path="/tour-details" element={<TourDetails />} />
          <Route path="/lexington-concord-tour" element={<LCTour />} />
          <Route path="/plymouth-cape-cod-tour" element={<PCCTour />} />
          <Route path="/newengland-seacoast-tour" element={<NESTour />} />
      </Routes>
      </Router>

  );
}

export default App;
