import React from 'react';
import {useLayoutEffect} from 'react';
import '../App.css';
import '../fonts.css';
import Hamburger from '../Hamburger';
import ContactTop from '../ContactTop';
import NavMenu from '../NavMenu';
import LCTourContent from './LCTourContent';
import Gallery from '../Gallery';
import Footer from '../Footer';


function LCTour() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    <div>
        <Hamburger />
        <NavMenu />
        <ContactTop />
        <LCTourContent/>
        <Gallery />
        <Footer />    
    </div>
    );
}

export default LCTour;