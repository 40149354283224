import React from 'react';
import {useLayoutEffect} from 'react';
import './App.css';
import './fonts.css';
import ContactTop from './ContactTop';
import Hamburger from './Hamburger';
import NavMenu from './NavMenu';
import HomeSlider from './components/HomeSlider'
import HomeContent from './components/HomeContent';
import Footer from './Footer';


function Home() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    <div>
        <Hamburger />
        <NavMenu />
        <ContactTop />
        <HomeSlider />
        <HomeContent/>
        <Footer />    
    </div>

    );
}

export default Home;