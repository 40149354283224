import React from 'react';
import './App.css';
import './fonts.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';



function Gallery() {

    return (

<Slide slidesToScroll={1} slidesToShow={3} indicators={true} autoplay={true} pauseOnHover={false} responsive={[{
        breakpoint: 1015,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
        }, {
        breakpoint: 630,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 1
        }
        }, {
            breakpoint: 30,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]}>

        <div><img className='gallery-pic' src={require('./images/gallery/Tavern.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/Minuteman.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/North-River-Marshfield.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/RevereLantern.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/Cape-Cod-Beach.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/John-Alden.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/Plymouth-Rock.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/Diorama.png')} /></div>
        <div><img className='gallery-pic' src={require('./images/gallery/Monument.png')} /></div>


    </Slide>

    );
    }
        
export default Gallery;