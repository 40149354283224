import React, { useState } from 'react';
import './App.css';
import './fonts.css';
import DropdownItin from './components/DropdownItin';

import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";


function NavMenu() {
    const [nav, setNavMenu] = useState(false);

    const changeHeight = () => {
        if (window.scrollY >= 20) {
            setNavMenu(true);
        }
        else {
            setNavMenu(false);
        }}
    

    window.addEventListener('scroll', changeHeight)

    return (
            <div id="header-wrapper">
            <div className={nav ? 'nav active' : 'nav'}>
                    <div id="logo">
                        <Link to="/">
                        <img id="logo-main" src={require("./images/logo.png")} />
                        </Link>
                    </div>
                    <div id="menu">
                        <ul>
                        <li>
                            <Link to="/about">
                                <h1 id="menu-item">About </h1></Link>
                        </li>
                        <li>
                            <Link to="/tour-details">
                            <h1 id="menu-item">Tour Details </h1></Link>
                        </li>
                        <li>
                    <div className='itineraries-menu'>
                        <li>
                            <Link to="/itineraries">
                            <h1 id="menu-item">Itineraries </h1></Link>
                                <ul id='submenu'>
                                    <li className='first-item'>
                                        <Link to="/lexington-concord-tour">Lexington & Concord</Link>
                                    </li>
                                    <li>
                                        <Link to="/newengland-seacoast-tour">New England Seacoast</Link>

                                    </li>
                                    <li className='last-item'>
                                        <Link to="/plymouth-cape-cod-tour">Plymouth & <br /> Cape Cod</Link>
                                    </li>
                                </ul>
                        </li>
                    </div>

                        </li> 
                        <li>
                            <a href="https://greatbostontours.rezdy.com/index" target="_blank">
                                <h1 className="menu-book">Book Now </h1>
                            </a>
                        </li>
                        </ul>
                    </div>
                 </div>
            </div>
    );
    }
        
export default NavMenu;