import React, { useState } from 'react';
import './App.css';
import './fonts.css';
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";


function ContactTop() {
    const [nav2, setContactTop] = useState(false);

    const changeHeight = () => {
        if (window.scrollY >= 120) {
            setContactTop(true);
        }
        else {
            setContactTop(false);
        }}
    

    window.addEventListener('scroll', changeHeight)

    return (
        <div className={nav2 ? 'nav2 active' : 'nav2'}>
            <div id="contact-top">
                <div id="contact-top2">
                    <ul><h1>
                        <li>1-888-346-5634</li>
                        <a href="https://www.facebook.com/greatbostontours" target="_blank">
                        <li>
                                <img id="social" src={require("./images/footer/facebook-icon.png")} />
                        </li>
                        </a>
                        <a href="https://www.instagram.com/greatbostontours/" target="_blank">
                        <li>
                            <img id="social" src={require("./images/footer/insta-icon.png")} />
                        </li>
                        </a>
                    </h1>
                    </ul>
                </div>
        </div>
        </div>
            
    );
    }
        
export default ContactTop;