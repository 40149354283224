import React from 'react';
import '../App.css';
import '../fonts.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";

function HomeContent() {
    return (
        <div className='page'>
            <div className='parchment-box'>
                <div className='blue-boxes-wrapper'>
                    
                    <div className='blue-boxes'>
                        <div className='blue-box-text'>
                          <div>
                            <h1>LEXINGTON & CONCORD TOUR</h1>
                            <p>
                            Explore the events that sparked the American Revolution. 
                            Visit Minuteman Historical Park and learn about how our nation's founders won the war against Britain's massive army.
                            </p>
                            </div>
                          <div>
                                <Link to="/lexington-concord-tour">
                                    <h1 className="menu-book" id='blue-box-button'>LEARN MORE</h1>
                                </Link>
                          </div>
                        </div>
                    </div>
                    
                    <div id='column-pic-wrap'>
                    <img id='column-pic' src={require('../images/buckman-tavern.png')} />
                    </div>

                    <div className='blue-boxes'>
                        <div className='blue-box-text'>
                          <div>
                            <h1>New England Seacoast Tour</h1>
                            <p>
                            See the spectacular New England Coast, including Salem, Gloucester, Rockport, and Essex.
                            Breathe in the salty air as you visit harbors, lighthouses, and picturesque beaches.
                            </p>
                          </div>
                          <div>
                                <Link to="/newengland-seacoast-tour">
                                    <h1 className="menu-book" id='blue-box-button'>LEARN MORE</h1>
                                </Link>
                          </div>
                        </div>
                    </div>

                    <div id='column-pic-wrap'>
                      <img id='column-pic' src={require('../images/cape-cod-tour-beach.png')} />
                    </div>
                    
                    <div className='blue-boxes'>
                        <div className='blue-box-text'>
                          <div>
                            <h1>PLYMOUTH & CAPE COD TOUR</h1>
                            <p>
                            Discover the charm of Cape Cod and learn about the first European settlers in America. 
                            See where the first Pilgrims landed at Plymouth Rock learn and how they developed the colonies.
                            </p>
                            </div>
                            <div class='divider'></div>
                          <div>
                                <Link to="/plymouth-cape-cod-tour">
                                    <h1 className="menu-book" id='blue-box-button'>LEARN MORE</h1>
                                </Link>
                          </div>
                        </div>
                    </div>

                    <div id='column-pic-wrap'>
                      <img id='column-pic' src={require('../images/Mayflower-Plymouth-harbor.png')} />
                    </div>
                   
                </div>
                
                <div className='pic-wrapper' id='hide-mobile'>

                    <div >
                    <img id='pic-home'src={require('../images/buckman-tavern.png')} />
                    </div>

                    <div>
                    <img id='pic-home'src={require('../images/cape-cod-tour-beach.png')} />
                    </div>

                    <div>
                    <img id='pic-home'src={require('../images/Mayflower-Plymouth-harbor.png')} />
                    </div>

                </div>
            </div>
            

        <div className='lighthouse-box'>
          <div className='lighthouse-content'>
            <div>
              <Link to="/newengland-seacoast-tour"><h1>Explore the New England Seacoast</h1></Link>
            </div>
          </div>
        </div>

        <div className='parchment-box' id='cartoon-wrap'>
          <img id='cartoon' src={require('../images/cartoon-boston.png')} />
        </div>
        
      </div>

);
}

export default HomeContent;