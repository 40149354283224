import React,  { useState } from 'react';
import './App.css';
import './fonts.css';
import {
    Link
} from "react-router-dom";


function Hamburger() {
    
        const [menuActive, setMenuActive] = useState(false);
      
        const toggleMenu = () => {
          setMenuActive((prev) => !prev);
        };

    return (
        <>
        <div className={`off-screen-menu ${menuActive ? 'active' : ''}`} id='hide-comp'>
            <ul>
                <li>
                    <Link to="/">HOME</Link></li>
                <li>
                    <Link to="/about">ABOUT</Link></li>
                <li>
                    <Link to="/tour-details">TOUR DETAILS</Link></li>
                <li>
                    <Link to="/itineraries">ITINERARIES</Link></li>
                <li>
                    <a href="https://greatbostontours.rezdy.com/index" target="_blank">BOOK NOW</a></li>
            </ul>
        </div>

        <nav>
            <div className={`ham-menu ${menuActive ? 'active' : ''}`} onClick={toggleMenu} id='hide-comp'>
                    <span></span>
                    <span></span>
                    <span></span>
            </div>
        </nav>

        <div id="logo-ham">
                        <Link to="/">
                        <img id="logo-main-ham" src={require("./images/logo.png")} />
                        </Link>
        </div>

        </>

        
    );
}

export default Hamburger;