import React from 'react';
import '../App.css';
import '../fonts.css';

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

function ItinerariesContent() {
    return (
        
    <div className='page'>
        <div className='parchment-box' id='itin-page'>
            <div className='itin-page-wrapper'>
                
                <div className='itin-page-box'>
                    <Link to="/lexington-concord-tour"><h1>Lexington & Concord Tour</h1></Link>
                </div>

                <div className='itin-page-box'>
                    <Link to="/newengland-seacoast-tour"><h1>New England Seacoast Tour</h1></Link>
                </div>

                <div className='itin-page-box'>
                    <Link to="/plymouth-cape-cod-tour"><h1>Plymouth & Cape Cod Tour</h1></Link>
                </div>

            </div>
        </div>
    </div>

    )
}

export default ItinerariesContent;