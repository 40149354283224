import React from 'react';
import {useLayoutEffect} from 'react';
import '../App.css';
import '../fonts.css';
import {
    BrowserRouter as Router,
    Route,
    Link
} from "react-router-dom";

function NESTourContent() {

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    
    return (
    
        <div className='page'>

        <div className='parchment-box' >
            <h1 className='itin-title'>New England Seacoast Tour</h1>
        </div>
        
        <div className='blue-box' id='beige'>

            <div className='picture-text-container'>
                    <img id='minuteman' src={require("../images/harborman.jpg")} />
                <div className='right-column-list' id='itin-column'>
                
                    <h2 className='subheading3' id='center-phone'>Itinerary for the Day</h2>
                      <div className='justify' id='wider'>
                        <p><strong>Salem:</strong> Leave Boston and the hustle and bustle of city life and enjoy the drive north to Salem. Be transported in time as you learn about the 1692 witchcraft trials in which 20 residents met their fate. Learn about historical local Cotton Mather. Walk the floors of the House of the Seven Gables, the title of the novel by Nathaniel Hawthorne. See the National Salem Historic Site alongside the waterfront, and visit the old Custom House. See the Friendship, an exacting replica of an 1800s schooner.</p>

                        <p><strong>Gloucester:</strong> An important fishing port reliant on Georges Bank, one of the richest fishing grounds in the world blending into the Grand Banks of Nova Scotia and Newfoundland. Gloucester is home to the world-famous Gorton’s Seafood of Gloucester!</p>

                        <p><strong>Rockport:</strong> Take a leisurely stroll around the artist colony at Bearskin Neck and window-shop past one of the 35 art galleries at one of the oldest art colonies in America. Join countless photographers and painters in taking snapshots of the iconic Motif No. 1 fishing shack overlooking picturesque Salem harbor. Snap a shot of the many historic lighthouses still protecting mariners from dangerous shores.</p>

                        <p><strong>Essex:</strong> A small town renowned for shipbuilding, and home of an important clamming industry with its pure tidal waters. Stop for lunch at Woodman’s of Essex, serving seafood for over 100 years. You may choose to imbibe in a delicious fried clam roll–Woodman's Seafood invented it! Did you know that the movie The Crucible was filmed in Essex?</p>
                      </div> 
                </div>
            </div>

            <div className='timing' id='padding-bottom'>
            <hr />
                <p>Pickups for this tour start at 8:05 AM, and the tour length is approximately 8.5 hours.</p>
                        <p>Adults: $159</p>
                        <p>Children: $119</p>
            <hr />
            </div>
        </div>

        <div className='map-box'>
          <div className='map-content'>
            <div>
                <h2 className='subheading'>INCLUDED</h2>
                <ul className='details-list'>
                    <li>Round-Trip Transportation (from for a designated locations)</li>
                    <li>Hotel Pick-Up/Drop-Off (only for large groups)</li>
                    <li>Professional Driver/Guide</li>
                    <li>Scenic Coastal Bypass Highway</li>
                    <li>National Maritime Historic Site</li>
                    <li>Bottled Water</li>
                </ul>

                <h2 className='subheading' id='margin-top'>NOT INCLUDED</h2>
                <ul className='details-list'>
                    <li>Lunch</li>
                    <li>Gratuities</li>
                </ul>
            </div>

            <div>
                <h2 className='subheading'>TOUR HIGHLIGHTS</h2>
                <ul className='details-list'>
                    <li>Escorted tour with an experienced tour guide</li>
                    <li>House of Seven Gables (admission included)</li>
                    <li>Salem Maritime National Historic Site</li>
                    <li>Gloucester Fishermen's Memorial</li>
                    <li>Fishermen’s Wharf</li>
                    <li>Eastern Point Lighthouse</li>
                    <li>Rockport’s Artist Colony at Bearskin Neck</li>
                </ul>
            </div>

          </div>
        </div>
        

        <div className='parchment-box'>
            <h1 className='itin-description-title'>SEE THE REAL NEW ENGLAND SEACOAST</h1>
              <div className='justify'>

              <p>On your journey, see the incredible beauty and history of New England as your guide navigates the Coastal Scenic Byway. Smell the aroma of salt air as you skirt along rugged coastal beaches, harbors, lighthouse bases and much more during this all-day tour from Boston. Stop at quaint villages settled in the early to mid-1600s: Salem, Gloucester, Rockport, and Essex. Learn about famous personages like Cotton Mather of the witchcraft trials in 1692 where twenty locals met their fate. Learn about the history of trade, and fishing including the tragic story of the Andrea Gail, the fishing vessel that left from Gloucester in search of swordfish on a glass sea which inspired the book/movie "The Perfect Storm". Walk Bearskin Neck in Rockport, with 35 artist galleries, Snap a photo of Eastern Point Lighthouse and the fishing shack Motif 1.</p>

            </div>

            <Link to="/tour-details" className='center'>
                        <h2 className='details-button'>MORE DETAILS</h2>
                </Link>

        </div>
    </div>


    );
}

export default NESTourContent;