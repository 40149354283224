import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";

const HomeSlider = () => {

  return (
    <div className='hero-wrapper'>
    
    <div className='hero-slider'>
        <Fade
          arrows={false}
          duration={4900}
          onChange={function noRefCheck() { } }
          onStartChange={function noRefCheck() { } }
          pauseOnHover={false}
          transitionDuration={1300}
        >
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero1.png')} />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero2.png')} />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero3.png')} />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero4.png')} />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero5.png')} />
            </div>
          </div>
          <div className="each-slide">
            <div>
              <img src={require('../images/hero/hero6.png')} />
            </div>
          </div>
        </Fade>
      </div>
      
      <div className='hero-text-box'>
        <div className='hero-text'>
          <h1 id='hero-title'>UNFORGETTABLE DAY TOURS OF LEXINGTON & CONCORD, PLYMOUTH, CAPE COD, AND THE NEW ENGLAND SEACOAST</h1>
          <Link to="/">
          <h1 className="menu-book" id='hero-button'>LEARN MORE</h1></Link>
        </div>
      </div>

    </div>
  );
};

export default HomeSlider;